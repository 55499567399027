<template>
  <v-data-table :headers="headers" :items="desserts" sort-by="MTpBsTnpCd" :items-per-page="15" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat >
        <v-toolbar-title>部門</v-toolbar-title>
        <v-divider class="mx-4" inset vertical ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
           
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">新規部門追加</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.MTpBsTnpCd" label="部門コード"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.MTpBsTnpNm" label="部門名称"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">キャンセル</v-btn>
              <v-btn color="blue darken-1" text @click="save">保存</v-btn>
              <v-btn color="blue darken-1" text @click="savedat">マルチ更新</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.MTpBsTnpCd" label="部門コード" filled readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.MTpBsTnpNm" label="部門名称" filled readonly></v-text-field>
                  </v-col>
                </v-row>
            <v-card-title class="headline">このアイテムを削除してもよろしいですか？</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">キャンセル</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">削除する</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>
<script>
import axios from 'axios';
export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: '部署コード',align: 'start',value: 'MTpBsTnpCd'},
        {text: '部署名称', value: 'MTpBsTnpNm' },
        {text: '処理', value: 'actions', sortable: false },
      ],
      desserts: [],
      dmitems: [],
    //  editedIndex: -1,
      editedItem: {
        name: '',
        calories: '',
      },
    //  defaultItem: {
    //    name: '',
    //    calories: '',
    //   },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? '部門新規追加' : '部門修正処理'
      },
    },

    watch: {
      dialog (val) {val || this.close()},
      dialogDelete (val) {val || this.closeDelete()},
    },
    //プログラムが開始された時に実行
    created () {
      this.initialize()
    },
    //部門取得
    mounted: function() {
      axios.get('/api/TpBsMstALL')
      .then(response => {
        this.desserts = response.data;
      })
      .catch(error => {
        console.log(error)
      })
  },
    methods: {
      initialize () {
        this.desserts = [];
        axios.get('/api/TpBsMstALL')
        .then(response => {
        this.desserts = response.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    //修正アイコンが押された時
    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    //削除アイコンが押された時
    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    //--------------------------------------------------------------------
    //部門マスタ削除
    //--------------------------------------------------------------------
    deleteItemConfirm () {
      const fomeDLgo = {BRMSDCD: this.editedItem.MTpBsTnpCd};
      axios.post('/api/TpBsMstDPS',fomeDLgo)
      .then(response => {   //更新後の処理
          this.dmitems = response
          this.dialogDelete = false;  
          this.initialize()
        })
        .catch(error => {
        console.log(error)
        })
    },
    //ダイアログを終了
    close () {
      this.dialog = false
      this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
     })
    },
    //削除処理でダイアログを終了
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      })
    },
    //保存処理が押された時
    save () {
      //--------------------------------------------------------------------
      //部門マスタ更新/追加
      //--------------------------------------------------------------------
      const fomeUPgo = { MTpBsTnpCd: this.editedItem.MTpBsTnpCd, MTpBsTnpNm: this.editedItem.MTpBsTnpNm};
      axios.post('/api/TpBsMstUAD',fomeUPgo)
         // axios.post('http://localhost:3000/TpBsMstUPS',fomeUPgo)
          .then(response => {
             this.dmitems = response
             this.initialize()
            })
            .catch(error => {
            console.log(error)
            })
        this.close()
      },
      //--------------------------------------------------------------------
      //部門マスタマルチ更新/追加
      //--------------------------------------------------------------------
     savedat () {
          const datax = [
                  ['951', 'テスト951-3'], ['952', 'テスト952-3'], ['953', 'テスト953-3']
          ];
         axios.post('/api/TpBsMstMUA',datax)
          .then(response => {
             this.dmitems = response
             this.initialize()
            })
            .catch(error => {
            console.log(error)
            })
            this.close()
     },
  }
}
</script>